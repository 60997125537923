.DateInput_input {
    text-align: center;
}

.kTableCell_overlay_container {
    position: relative;
}

.kTableCell_overlay {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
