body {
    height: 100vh
}

#root {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
}

div#root > div:first-child {
    width: 100%;
}

main {
    width: 100%;
}